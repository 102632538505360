import mCustomScrollbar from 'malihu-custom-scrollbar-plugin';
import mousewheel from 'jquery-mousewheel';
export function tabAreaFucn() {
  let tabs = $(".tab");
  $(".tab").on("click", function () {
    $(".active").removeClass("active");
    $(this).addClass("active");
    const index = tabs.index(this);
    $(".content").removeClass("show").eq(index).addClass("show");
    
    $("#jsServiceScr1").mCustomScrollbar("scrollTo","top",{scrollInertia:0});
    $("#jsServiceScr2").mCustomScrollbar("scrollTo","top",{scrollInertia:0});
    
  });
}

export function serviceScrollFunc() {
  let serviceResizecheck;
  serviceResizecheck = $('#responsibleCheck').width();
  if (serviceResizecheck == '2') {
    var csObj = new Object();
    csObj.horizontalScroll = true;
    $("#jsServiceScr1").mCustomScrollbar(csObj);
    var csObj2 = new Object();
    csObj2.horizontalScroll = true;
    $("#jsServiceScr2").mCustomScrollbar(csObj2);
  }
}